import utilColors from "../../styles/utilColors";

const styles = {
  formControlBox: {
    display: "flex",
    justifyContent: "end",
    mt: "-15px",
    mb: "5px",
  },
  appSelect: { height: "35px" },
  tabList: { mt: "-35px" },
  iframe: {
    background: utilColors.white,
    width: "100%",
    height: "87.5vh",
  },
};

export default styles;
